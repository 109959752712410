import { Box, Stack, styled, Grid, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import HeroImage from "../../../Images/PngItem_405617.png";
import Demo from "./Demo";

const AboutUs = ({ data }) => {
  const [units, setUnits] = useState({
    floors: 0,
    amenities: 0,
    open_spaces: 0,
    towers: 0,
  });
  const [image, setImage] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectDes, setProjectDes] = useState("");

  const setUnitvalue = () => {
    setUnits(data["number_of_units"]);
    setImage(data["imagelist"]);
    setProjectName(data["project_name"]);
    setProjectDes(data["project_description"]);
  };
  useEffect(() => {
    setUnitvalue();
  }, []);

  const CustomContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      marginBottom: theme.spacing(),
    },
  }));

  const CustomBox1 = styled(Box)(({ theme }) => ({
    marginTop: 100,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
      marginTop: 50,
    },
  }));

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const LargeText = styled(Typography)(({ theme }) => ({
    fontSize: "35px",
    color: "black",
    fontWeight: "100",
    fontFamily: "Raleway, Arial",

    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      flexDirection: "column",
      justifyContent: "center",
    },
  }));

  const SmallText = styled(Typography)(({ theme }) => ({
    fontSize: "18px",
    color: "black",
    fontFamily: "Poppins",
    fontWeight: "100",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      flexDirection: "column",
      justifyContent: "center",
    },
  }));

  const SmallText1 = styled(Typography)(({ theme }) => ({
    fontSize: "20px",
    color: "black",
    marginLeft: 40,
    marginRight: 10,
    fontWeight: "100",
    fontFamily: "Poppins",
    width: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: 300,
    },
  }));

  const TextFlexbox = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(10),
    display: "flex",
    justifyContent: "space-between",

    padding: theme.spacing(0, 5, 0, 5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(4),
    },
  }));

  const ImgContainer = styled(Box)(({ theme }) => ({
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "500px",
    width: "90%",
    display: "flex",

    marginTop: 20,

    [theme.breakpoints.down("sd")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "500px",
      width: "0%",
    },
  }));
  const ImgContainer1 = styled(Box)(({ theme }) => ({
    height: "100%",
    width: "100%",

    [theme.breakpoints.down("sd")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  }));
  const LargeText1 = styled(Typography)(({ theme }) => ({
    marginTop: 40,
    fontFamily: "Poppins",
    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));
  const LargeText2 = styled(Typography)(({ theme }) => ({
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#5A6473",
    marginLeft: 60,
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      fontSize: "9px",
      marginRight: 60,
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const BoxP = styled(Box)(({ theme }) => ({
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  }));

  return (
    <Box
      sx={{
        margin: {
          xs: 1, // Margin for extra-small screens (e.g., mobile)
          sm: 4, // Margin for small screens (e.g., tablets)
          md: 6, // Margin for medium screens (e.g., small laptops)
          lg: 8, // Margin for large screens (e.g., desktops)
          xl: 10, // Margin for extra-large screens (e.g., large desktops)
        },
      }}
    >
      <Demo data={data["query_enable"]} />
      <CustomBox>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              sx={{ marginLeft: { xs: 2, md: 5, fontFamily: "Poppins" } }}
            >
              ABOUT <span style={{ color: "red" }}>{projectName}</span>
            </Typography>

            <Typography
              sx={{
                marginLeft: {
                  xs: 2,
                  md: 5,
                  fontFamily: "Poppins",
                  fontSize: "18px",
                },
              }}
            >
              {projectDes}
            </Typography>
          </Grid>
        </Grid>
      </CustomBox>
      <Box sx={{ display: "flex" }}>
        <Stack direction={{ xs: "column", sm: "row" }}>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <TextFlexbox
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: ["10px", "100px"],
              }}
            >
              <BoxP
                sx={{ width: ["100%", "auto"], margin: ["5px 0", "0 10px"] }}
              >
                <LargeText>
                  {units["acres"] ? units["acres"] : <Box />}
                </LargeText>
                {units["acres"] ? <SmallText>Acres</SmallText> : <Box />}
              </BoxP>

              <BoxP
                sx={{ width: ["100%", "auto"], margin: ["5px 0", "0 10px"] }}
              >
                {units["floors"] ? (
                  <>
                    <LargeText>{units["floors"]}</LargeText>
                    <SmallText>Floors</SmallText>
                  </>
                ) : (
                  <>
                    <Box />
                  </>
                )}
              </BoxP>

              <BoxP
                sx={{ width: ["100%", "auto"], margin: ["5px 0", "0 10px"] }}
              >
                {units["amenities"] ? (
                  <>
                    <LargeText>{units["amenities"]}</LargeText>
                    <SmallText>Amenities</SmallText>
                  </>
                ) : (
                  <>
                    <Box />
                  </>
                )}
              </BoxP>
            </TextFlexbox>
          </Box>
        </Stack>

        {/* Add space between the two Stack components */}

        <Stack direction={{ xs: "column", sm: "row" }}>
          <Box
            sx={{
              width: "50%",
            }}
          >
            <TextFlexbox
              sx={{
                display: "flex",
                flexDirection: ["column", "row"], // Adjust as needed for responsive layout
                alignItems: ["center", "flex-start"], // Adjust as needed for responsive alignment
                gap: ["10px", "100px"], // Adjust spacing as needed for responsiveness
              }}
            >
              <BoxP
                sx={{
                  width: ["100%", "auto"],
                  margin: ["5px 0", "0 10px"],
                }}
              >
                {units["open_spaces"] ? (
                  <>
                    <LargeText>{units["open_spaces"]}sq</LargeText>

                    <SmallText> Feet .Open Spaces</SmallText>
                  </>
                ) : (
                  <>
                    <Box />
                  </>
                )}
              </BoxP>

              <BoxP
                sx={{
                  margin: "0", // No margin for the second BoxP in this example
                }}
              >
                <LargeText>
                  {" "}
                  {units["towers"] ? units["towers"] : <Box />}{" "}
                </LargeText>
                {units["towers"] ? <SmallText>Towers</SmallText> : <Box />}
              </BoxP>
            </TextFlexbox>
          </Box>
        </Stack>
      </Box>

      {/* <Box marginTop={10}>
        <LargeText variant={"h2"} color="black" align="center">
          EXPERIENCE THE <span className=" text-red-600">SKY VILLA</span>
        </LargeText>
        <SmallText
          align="center"
          color="black"
          variant={"h6"}
          marginBottom={10}
        >
          {" "}
          A lifestyle project which promises you a life of unending ease to give
          you the best of modern living.
        </SmallText>

        <Box>
          {" "}
          <ImgContainer1>
            <img src={image[4]} alt="" />
          </ImgContainer1>{" "}
        </Box>
      </Box> */}
      <CustomContainer>
        <CustomBox1>
          <Box>
            <LargeText
              variant="h3"
              sx={{ marginLeft: { xs: 2, md: 5, fontFamily: "Poppins" } }}
            >
              ABOUT{" "}
              <span className=" text-red-600">
                {" "}
                CHANGE YOUR
                <p>VIEWPOINT</p>{" "}
              </span>
            </LargeText>
          </Box>
          <SmallText1>
            {" "}
            <SmallText>
              Experience the breathtaking beauty of both the city and nature in
              all their glory from the vantage point of {projectName}.
            </SmallText>{" "}
            Here, a bird’s eye view assumes a different meaning and you can
            spend hours just basking in the open air balcony and lounging in
            nature’s luxury. There’s nothing more soulful than spending time
            with your own self, and escaping the routine of a structured life.
          </SmallText1>
        </CustomBox1>

        <Box
          component="button"
          variant="body2"
          sx={{
            backgroundImage: `url(${image[1]})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "black",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: 500,
            width: "100%",
            display: "flex",
            justifyContent: "center",

            marginTop: 20,
            marginBottom: 20,
          }}
          // onClick={DownloadPDF}
        >
          {/* {loader ? <>Downloading....</> : Download} */}
          {/* <img src={Happypic} alt="Logo" /> */}
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default AboutUs;
