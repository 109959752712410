import React, { useState, useEffect } from "react";
import { Box, Grid, Container, Typography, styled } from "@mui/material";

const Finance = ({ data }) => {
  const [bankLogo, setBankLogo] = useState([]);

  const setUnitvalue = () => {
    setBankLogo(data["finance_partner"]);
  };

  useEffect(() => {
    setUnitvalue();
  }, []);

  return (
    <>
      {bankLogo ? (
        <Box
          sx={{
            margin: {
              xs: 1,
              sm: 4,
              md: 6,
              lg: 8,
              xl: 10,
            },
          }}
        >
          <Typography
            variant={"h4"}
            fontFamily={"Poppins"}
            color="black"
            align="left"
          >
            Finance Partners
          </Typography>
        </Box>
      ) : (
        <Box />
      )}
      <Container>
        <Box py={3}>
          <Grid container spacing={2} justifyContent="center">
            {bankLogo.length > 0 ? (
              bankLogo.map((bankLogo, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <img
                    src={bankLogo}
                    alt={`Bank Logo ${index}`}
                    height={100}
                    width={150}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  No banks available.
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default Finance;
