import React from "react";
import NavbarHeader from "../NavbarHeader";
import Hero from "../Hero/Hero";
import Categories from "../Categories/Categories";
import FirstPage from "../FirstPage/FirstPage";
import Brouch from "../Brouch/Brouch";
import Cliends from "../Cliends/Cliends";
import FooterPage from "../Footer/FooterPage";
import EnquireForm from "../Brouch/EnquireForm";

const Home = () => {
  return (
    <div>
      <NavbarHeader />
      <Hero />
      <br />
      <br />
      <FirstPage />
      <Categories />
      <Brouch />
      <EnquireForm />

      <Cliends />
      <br />
      <br />
      <br />
      <FooterPage />
    </div>
  );
};

export default Home;
