import { Box, Container, styled, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import FooterPage from "../Footer/FooterPage";

import SyncIcon from "@mui/icons-material/Sync";
import NavbarHeader from "../NavbarHeader";
import AboutSm from "./AboutSm";

const About = () => {
  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));
  const [data, setData] = useState(null);

  // Make API request to fetch the JSON file
  // useEffect(() => {
  //   Award();
  // }, []);
  // Make API request to fetch the JSON file
  // const Award = async () => {
  //   axios
  //     .get(
  //       "https://81zj99m8y7.execute-api.ap-south-1.amazonaws.com/samriddhi/award_and_recognition_all_data"
  //     )
  //     .then((response) => {
  //       // Handle the response and store the data
  //       setData(response.data["body-json"]["body"]["Items"]);
  //       // console.log(response.data);
  //     })
  //     .catch((error) => {
  //       // Handle any errors
  //       console.error("Error:", error);
  //     });
  // };

  return (
    <>
      <NavbarHeader />
      <br />
      <br />
      <AboutSm />

      <br />
      <FooterPage />
    </>
  );
};

export default About;
