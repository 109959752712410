import React from "react";
import { Box, Typography, styled, Grid } from "@mui/material";
import NavbarHeader from "../NavbarHeader";
import FooterPage from "../Footer/FooterPage";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const CardContainer = styled(Box)(({ theme }) => ({
  borderRadius: "20px",
  padding: "20px 30px",
  backgroundColor: "rgb(255, 255, 255)",
  boxShadow: theme.shadows[8],
  marginTop: "30px",
  width: "100%",
  height: "400px",
  maxWidth: "400px",
  margin: "0 auto",
  position: "relative",
  transition: "transform 0.3s",

  "&:hover": {
    transform: "scale(1.05)",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    maxWidth: "400px",
  },
}));

const ApplyNowButton = styled(Link)(({ theme }) => ({
  display: "inline-block",
  backgroundColor: theme.palette.primary.main,
  color: "#fff",
  padding: "10px 20px",
  borderRadius: "4px",
  textDecoration: "none",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  position: "absolute",
  bottom: "50px",
  left: "50%",
  transform: "translateX(-50%)",

  [theme.breakpoints.down("sm")]: {
    bottom: "10px",
  },
}));

const Faq = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const handleApplyNow = () => {
    navigate("/FormCareer");
  };

  return (
    <>
      <NavbarHeader />
      <Box
        sx={{
          marginTop: "60px",
          paddingBottom: "30px",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h3"
          component="h2"
          style={{
            marginBottom: "0.5rem",
            fontWeight: 700,
            padding: isMobileView ? "10px" : "25px",
          }}
        >
          Let's Work Together
        </Typography>
        <Typography
          variant="body1"
          component="p"
          style={{
            marginBottom: "2rem",
            color: "rgb(141, 162, 181)",
            padding: isMobileView ? "10px" : "25px",
          }}
        >
          Just like nature has space for everyone, we too have space for
          like-minded people who share a similar passion as ours. If you have
          the potential to scale newer heights for SAMRIDDHI, then you are
          welcome on board. If you look forward to working in a positive and
          collaborative environment, then SAMRIDDHI Infraworks is the right
          place for you.
          <br />
          <br />
          <Typography
            variant="body1"
            component="span"
            style={{
              color: "lightseagreen",
              fontWeight: 400,
              fontSize: "1.5em",
              padding: isMobileView ? "10px" : "30px",
            }}
          >
            Interested to work with us? Check out open positions.
          </Typography>
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <CardContainer>
              <Typography
                variant="h3"
                component="h3"
                style={{
                  marginBottom: "15px",
                  fontSize: { xs: "1.5rem", sm: "2rem" },
                }}
              >
                <ion-icon
                  className="md hydrated"
                  name="bookmark-outline"
                  role="img"
                  style={{ visibility: "inherit", marginRight: "10px" }}
                />
                Project Manager
              </Typography>
              <Typography
                variant="body1"
                component="p"
                style={{
                  marginBottom: "1rem",
                  lineHeight: 1.8,
                  fontSize: { xs: "1rem", sm: "1.2rem" },
                }}
              >
                We are looking for a highly motivated candidate to join our
                team. The successful candidate will assist in implementing and
                improving...
              </Typography>
              <Typography
                variant="body1"
                component="p"
                style={{
                  marginBottom: "1rem",
                  lineHeight: 1.8,
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                }}
              >
                <ion-icon
                  className="md hydrated"
                  name="checkmark-circle"
                  role="img"
                  style={{ visibility: "inherit", marginRight: "5px" }}
                />
              </Typography>
              <ApplyNowButton onClick={handleApplyNow}>
                Apply Now
              </ApplyNowButton>
            </CardContainer>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardContainer>
              <Typography
                variant="h3"
                component="h3"
                style={{
                  marginBottom: "15px",
                  fontSize: { xs: "1.5rem", sm: "2rem" },
                }}
              >
                <ion-icon
                  className="md hydrated"
                  name="bookmark-outline"
                  role="img"
                  style={{ visibility: "inherit", marginRight: "10px" }}
                />
                Engineer
              </Typography>
              <Typography
                variant="body1"
                component="p"
                style={{
                  marginBottom: "1rem",
                  lineHeight: 1.8,
                  fontSize: { xs: "1rem", sm: "1.2rem" },
                }}
              >
                The Engineer is responsible for designing, developing, and
                implementing projects. They will work closely with the team to
                ensure the successful execution of project plans.
              </Typography>
              <Typography
                variant="body1"
                component="p"
                style={{
                  marginBottom: "1rem",
                  lineHeight: 1.8,
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                }}
              >
                <ion-icon
                  className="md hydrated"
                  name="checkmark-circle"
                  role="img"
                  style={{ visibility: "inherit", marginRight: "5px" }}
                />
              </Typography>
              <ApplyNowButton onClick={handleApplyNow}>
                Apply Now
              </ApplyNowButton>
            </CardContainer>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardContainer>
              <Typography
                variant="h3"
                component="h3"
                style={{
                  marginBottom: "15px",
                  fontSize: { xs: "1.5rem", sm: "2rem" },
                }}
              >
                <ion-icon
                  className="md hydrated"
                  name="bookmark-outline"
                  role="img"
                  style={{ visibility: "inherit", marginRight: "10px" }}
                />
                Supervisor
              </Typography>
              <Typography
                variant="body1"
                component="p"
                style={{
                  marginBottom: "1rem",
                  lineHeight: 1.8,
                  fontSize: { xs: "1rem", sm: "1.2rem" },
                }}
              >
                The Supervisor will oversee the day-to-day activities on the
                project site. They will manage the work progress, ensure safety
                regulations are followed, and monitor the quality of work.
              </Typography>
              <Typography
                variant="body1"
                component="p"
                style={{
                  marginBottom: "1rem",
                  lineHeight: 1.8,
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                }}
              >
                <ion-icon
                  className="md hydrated"
                  name="checkmark-circle"
                  role="img"
                  style={{ visibility: "inherit", marginRight: "5px" }}
                />
              </Typography>
              <ApplyNowButton onClick={handleApplyNow}>
                Apply Now
              </ApplyNowButton>
            </CardContainer>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CardContainer>
              <Typography
                variant="h3"
                component="h3"
                style={{
                  marginBottom: "15px",
                  fontSize: { xs: "1.5rem", sm: "2rem" },
                }}
              >
                <ion-icon
                  className="md hydrated"
                  name="bookmark-outline"
                  role="img"
                  style={{ visibility: "inherit", marginRight: "10px" }}
                />
                Accounts Officer
              </Typography>
              <Typography
                variant="body1"
                component="p"
                style={{
                  marginBottom: "1rem",
                  lineHeight: 1.8,
                  fontSize: { xs: "1rem", sm: "1.2rem" },
                }}
              >
                The Accounts Officer will handle financial aspects of the
                project. They will manage budgets, track expenses, and ensure
                financial compliance.
              </Typography>
              <Typography
                variant="body1"
                component="p"
                style={{
                  marginBottom: "1rem",
                  lineHeight: 1.8,
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                }}
              >
                <ion-icon
                  className="md hydrated"
                  name="checkmark-circle"
                  role="img"
                  style={{ visibility: "inherit", marginRight: "5px" }}
                />
              </Typography>
              <ApplyNowButton onClick={handleApplyNow}>
                Apply Now
              </ApplyNowButton>
            </CardContainer>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
            <CardContainer>
              <Typography
                variant="h3"
                component="h3"
                style={{
                  marginBottom: "15px",
                  fontSize: { xs: "1.5rem", sm: "2rem" },
                }}
              >
                <ion-icon
                  className="md hydrated"
                  name="bookmark-outline"
                  role="img"
                  style={{ visibility: "inherit", marginRight: "10px" }}
                />
                Stone keeper
              </Typography>
              <Typography
                variant="body1"
                component="p"
                style={{
                  marginBottom: "1rem",
                  lineHeight: 1.8,
                  fontSize: { xs: "1rem", sm: "1.2rem" },
                }}
              >
                We are looking for a highly motivated candidate to join our
                team. The successful candidate will assist in implementing and
                improving...
              </Typography>
              <Typography
                variant="body1"
                component="p"
                style={{
                  marginBottom: "1rem",
                  lineHeight: 1.8,
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                }}
              >
                <ion-icon
                  className="md hydrated"
                  name="checkmark-circle"
                  role="img"
                  style={{ visibility: "inherit", marginRight: "5px" }}
                />
              </Typography>
              <ApplyNowButton onClick={handleApplyNow}>
                Apply Now
              </ApplyNowButton>
            </CardContainer>
          </Grid> */}
          <Grid item xs={12} sm={6} md={4}>
            <CardContainer>
              <Typography
                variant="h3"
                component="h3"
                style={{
                  marginBottom: "15px",
                  fontSize: { xs: "1.5rem", sm: "2rem" },
                }}
              >
                <ion-icon
                  className="md hydrated"
                  name="bookmark-outline"
                  role="img"
                  style={{ visibility: "inherit", marginRight: "10px" }}
                />
                Store Keeper
              </Typography>
              <Typography
                variant="body1"
                component="p"
                style={{
                  marginBottom: "1rem",
                  lineHeight: 1.8,
                  fontSize: { xs: "1rem", sm: "1.2rem" },
                }}
              >
                The Store Keeper is responsible for managing project inventory
                and supplies. They will maintain accurate records of stock,
                monitor levels, and reorder materials when necessary.
              </Typography>
              <Typography
                variant="body1"
                component="p"
                style={{
                  marginBottom: "1rem",
                  lineHeight: 1.8,
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                }}
              >
                <ion-icon
                  className="md hydrated"
                  name="checkmark-circle"
                  role="img"
                  style={{ visibility: "inherit", marginRight: "5px" }}
                />
              </Typography>
              <ApplyNowButton onClick={handleApplyNow}>
                Apply Now
              </ApplyNowButton>
            </CardContainer>
          </Grid>

          {/* Add more Grid items for other cards as needed */}
        </Grid>
      </Box>
      <FooterPage />
    </>
  );
};

export default Faq;
