import { Grid, Typography, Stack } from "@mui/material";
import { Box } from "@mui/system";
import LinkIcon from "@mui/icons-material/Link";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import { Link } from "react-router-dom";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function FooterPage() {
  return (
    <Box
      sx={{
        background: "black",
        opacity: 0.8,
        color: "white",
        p: { xs: 2, md: 4 },
        pt: 4,
        pb: 4,
        fontSize: { xs: "12px", md: "14px" },
        mt: 10,
      }}
    >
      <hr />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Typography variant="h6" fontFamily={"Poppins"}>
            {" "}
            <LocationCityIcon style={{ color: "orangered" }} /> RealEstate
          </Typography>
          <Typography fontSize={16} fontFamily={"Poppins"}>
            Find your dream home with RealEstate. We provide the best deals in
            real estate.
          </Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography variant="h6" fontFamily={"Poppins"}>
            <LinkIcon style={{ color: "blue" }} /> Quick Links
          </Typography>
          <Stack sx={{ mt: 1 }}>
            <Link to="/" sx={{ color: "white", textDecoration: "none" }}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  textDecoration: "underline",
                  textDecorationColor: "transparent",
                  transition: "text-decoration-color 0.3s",
                  "&:hover": {
                    textDecorationColor: "yellow",
                  },
                }}
              >
                {" "}
                Home
              </Typography>
            </Link>
            <Link to="/about" sx={{ color: "white", textDecoration: "none" }}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  textDecoration: "underline",
                  textDecorationColor: "transparent",
                  transition: "text-decoration-color 0.3s",
                  "&:hover": {
                    textDecorationColor: "yellow",
                  },
                }}
              >
                {" "}
                About Us
              </Typography>
            </Link>
            <Link to="/career" sx={{ color: "white", textDecoration: "none" }}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  textDecoration: "underline",
                  textDecorationColor: "transparent",
                  transition: "text-decoration-color 0.3s",
                  "&:hover": {
                    textDecorationColor: "yellow",
                  },
                }}
              >
                {" "}
                Career
              </Typography>
            </Link>
            <Link to="/contact" sx={{ color: "white", textDecoration: "none" }}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  textDecoration: "underline",
                  textDecorationColor: "transparent",
                  transition: "text-decoration-color 0.3s",
                  "&:hover": {
                    textDecorationColor: "yellow",
                  },
                }}
              >
                {" "}
                Contact us
              </Typography>
            </Link>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" fontFamily={"Poppins"}>
            <LocationOnIcon style={{ color: "red" }} />
            Contact Info
          </Typography>

          <Typography lineHeight={2} fontSize={16} fontFamily={"Poppins"}>
            Samriddhi Infraworks Private Limited . Opp. Mayuri Regency Hotel
            Katchari Chowk Near Jain Petrol Supply, Hazaribag (Jharkhand)-825301
          </Typography>

          <Typography lineHeight={2} fontSize={16} fontFamily={"Poppins"}>
            <CallIcon style={{ color: "green" }} /> +919304371006
          </Typography>

          <Typography lineHeight={2} fontSize={16} fontFamily={"Poppins"}>
            <MailIcon style={{ color: "firebrick" }} />{" "}
            info@samriddhiinfraworks.com
          </Typography>
          <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
            <a
              href="https://www.facebook.com/Samriddhi.Infraworks.Private.Limited/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "block",
                marginBottom: "10px",
                color: "#3b5998",
              }}
            >
              <FacebookIcon sx={{ color: "blue" }} />
            </a>

            <a
              href="https://twitter.com/PromoSamriddhi"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "block",
                marginBottom: "10px",
                color: "#3b5998",
              }}
            >
              <TwitterIcon sx={{ color: "lightBlue" }} />
            </a>
            <a
              href="https://www.linkedin.com/company/samriddhi-infraworks-private-limited"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "block",
                marginBottom: "10px",
                color: "#3b5998",
              }}
            >
              <LinkedInIcon sx={{ color: "blueAccent" }} />
            </a>
          </Stack>
        </Grid>
      </Grid>
      <hr />

      <span
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{
            marginLeft: "8px",
          }}
        >
          Hosted and designed by &nbsp;
        </span>
        <Box
          sx={{
            height: 40,
            width: 40,
            borderRadius: 10,
          }}
          component="img"
          src=" https://s3.ap-south-1.amazonaws.com/p5digital.in/p5.jpeg"
          alt=""
        />
        <span
          style={{
            marginLeft: "8px",
          }}
        >
          <a
            href="https://www.p5digital.in/index.html"
            style={{ fontFamily: "Poppins" }}
          >
            P5 Digital Solutions
          </a>
        </span>
      </span>
    </Box>
  );
}
