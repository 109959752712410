import { Button, TextField, Grid } from "@mui/material";
import React from "react";
import HeroImg from "../../Images/ENQ.png";

// import Archi from "../../Images/village1.jpg";
// import { Link } from "react-router-dom";
// import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import DoubleArrowRoundedIcon from "@mui/icons-material/DoubleArrowRounded";

import { Box, styled, Typography } from "@mui/material";

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",

  justifyContent: "center",
  backgroundColor: " #f3e5f5",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
}));
const ImgContainer = styled(Box)(({ theme }) => ({
  height: 500,
  display: "flex",
  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const EnquireForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const [phoneError, setPhoneError] = useState("");
  const handleChange = (e) => {
    const phoneNumber = e.target.value;

    if (phoneNumber && !phoneNumber.match(/^[6-9]/)) {
      setPhoneError(
        "Invalid phone number. Please enter a valid 10-digit number starting from 6 to 9."
      );
    } else {
      setPhoneError(""); // Reset error if valid phone number is entered
    }

    setNumber(phoneNumber);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = JSON.stringify({
      name: name,

      email: email,
      message: message,
      phone: phone,
    });
    const ContactData = async () => {
      await axios
        .post(
          "https://81zj99m8y7.execute-api.ap-south-1.amazonaws.com/samriddhi/contact",

          values
        )
        .then((response) => {
          if (response.data["body-json"]["statusCode"] === 404) {
            toast.error("Error submitting form. Please try again later.");
          } else if (response.data["body-json"]["statusCode"] === 500) {
            toast.error("Error submitting form. Please try again later.");
          } else if (response.data["body-json"]["statusCode"] === 200) {
            toast.success("Enquire submitted successfully!");
          }
        })
        .catch((error) => {
          toast.error("Error submitting form. Please try again later.");
        });
      setName("");
      setEmail("");
      setNumber("");
      setMessage("");
    };
    //
    ContactData();
  };

  return (
    <Box>
      <CustomBox>
        <CustomBox sx={{ flex: "1", marginTop: 5, padding: 5 }}>
          <Grid container direction="column" sx={{ height: "100%" }}>
            <Grid item xs={12} md={4}>
              <Box sx={{ p: 2 }}>
                <CustomBox className="flex  justify-center">
                  <DoubleArrowRoundedIcon
                    style={{
                      fontSize: "42px",
                      color: "orange",
                    }}
                  />
                  <Typography
                    variant="h4"
                    align="center"
                    mb={2}
                    color="Blue"
                    fontWeight={"bold"}
                    fontFamily={"Poppins"}
                  >
                    Enquire Now
                  </Typography>
                </CustomBox>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: 18,
                  }}
                >
                  Get in touch today to step into your dream home
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid direction="column">
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        margin="normal"
                        required
                      />
                      <TextField
                        fullWidth
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                        required
                        type="email"
                      />
                      <TextField
                        fullWidth
                        label="Phone Number"
                        value={phone}
                        onChange={handleChange}
                        margin="normal"
                        required
                        type="tel"
                        error={phoneError !== ""}
                        helperText={phoneError}
                      />

                      <TextField
                        fullWidth
                        label="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        margin="normal"
                        required
                        multiline
                        rows={4}
                      />
                      <Button variant="contained" type="submit">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </CustomBox>
        <CustomBox>
          <ImgContainer>
            <img src={HeroImg} alt="heroImg" className="h-full" />
          </ImgContainer>
        </CustomBox>
      </CustomBox>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
      />
    </Box>
  );
};

export default EnquireForm;
