import React from "react";

import { AppBar, IconButton, Toolbar, CssBaseline } from "@mui/material";
// import { Link } from "react-router-dom";
// import DrawerComponent from "./Drawer";
import Drawer1 from "./Drawer1";
import { Home } from "@mui/icons-material";
import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Box } from "@mui/material";

import { Typography } from "@mui/material";

function NavbarHeader() {
  // const classNamees = useStyles();
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar position="fixed" sx={{ background: "black" }}>
      <CssBaseline />
      <Toolbar>
        <Link to="/">
          <IconButton
            edge="start"
            color="yellow"
            aria-label="home"
            sx={{ color: "yellow" }}
          >
            <Home />
          </IconButton>
        </Link>
        <Box sx={{ marginLeft: isMobileView ? "0px" : "450px" }}>
          <Typography
            variant={"h5"}
            color="white"
            align="center"
            sx={{
              textShadow: "10px 20px 40px rgba(0, 0, 0, 0.5)",
              fontSize: isMobileView ? "15px" : "24px",
              fontFamily: "Young Serif",
              fontWeight: 600,
            }}
          >
            SAMRIDDHI INFRAWORKS PRIVATE LIMITED
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontSize: "15px",
              fontFamily: "Poppins",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            (Building With Vision, Quality And Pride)
          </Typography>
        </Box>
        <Drawer1 />
      </Toolbar>
    </AppBar>
  );
}
export default NavbarHeader;
