import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import HeroView from "./ViewPages/HeroView";
import AboutUs from "./ViewPages/AboutUs";
import Animities from "./ViewPages/Animities";
import axios from "axios";

import FloorPlan from "./ViewPages/FloorPlan";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import Brouchdw from "./ViewPages/Brouchdw";
import Finance from "./ViewPages/Finance";
import VideoDisplay from "./ViewPages/VideoDisplay";
import Gallery from "./ViewPages/Gallery";
import Spacification from "./ViewPages/Specification";
import New from "./ViewPages/New";
import Approval from "./ViewPages/approval";

const PropertiesDetails = () => {
  const { propId } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchAward = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        "https://81zj99m8y7.execute-api.ap-south-1.amazonaws.com/samriddhi/particular_property_all_data",
        { id: propId }
      );
      if (response.data["body-json"]["statusCode"] === 200) {
        setData(response.data["body-json"]["body"]["Item"]);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAward();
  }, [propId]);

  return isLoading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div>
      <Navbar />
      <HeroView data={data} />

      <AboutUs data={data} />
      <Brouchdw data={data["brochure"]} />

      <Spacification
        highlights={data["project_highlights"]}
        specification={data["specification"]}
      />

      <Animities
        amenitiesData={data["amenities"]}
        amenitiesTextData={data["amenities_text"]}
      />
      <Approval data={data} />
      <New data={data} />

      <FloorPlan
        data={data}
        floor={data["floorplan"]}
        masterPlan={data["masterplan"]}
      />

      <Gallery data={data} />
      <br />
      <br />

      <VideoDisplay videoItem={data["video"]} />
      <Finance data={data} />
    </div>
  );
};

export default PropertiesDetails;
