// import Home from "./Components/Home/Home";
// import AboutUs from "./Components/AboutUs/AboutUs";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

// import Home from "./Components/Pages/Home";
import Cotact from "./Components/Pages/Cotact";
import About from "./Components/Pages/About";
import Faq from "./Components/Pages/Faq";
import Home from "./Components/Home/Home";
import Tabs7 from "./Components/ViewAllPage/Tabs7";
import PropertiesDetails from "./Components/ViewAllPage/PropertiesDetails";
import CareerForm from "./Components/Pages/CareerForm";
import Tabs6 from "./Components/ViewAllPage/Tabs6";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {/* Pages */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Cotact />} />
        <Route path="/career" element={<Faq />} />

        {/* Components */}
        <Route path="/tab7" element={<Tabs7 />} />
        <Route path="/tab6" element={<Tabs6 />} />
        <Route
          path="/PropertiesDetails/:propId"
          element={<PropertiesDetails />}
        />
        <Route path="/FormCareer" element={<CareerForm />} />
      </Route>
    )
  );

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
