import React, { useState } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Spacification = ({ specification, highlights }) => {
  const [open, setOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleOpen = (src) => {
    setOpen(true);
    setImageSrc(src);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} justifyContent="center">
        {highlights ? (
          <Grid item xs={12} md={6}>
            <Typography
              variant={"h4"}
              color="black"
              fontFamily={"Poppins"}
              align="center"
            >
              Highlights
            </Typography>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={highlights}
                alt="Highlights"
                style={{ maxWidth: "100%", cursor: "pointer" }}
                onClick={() => handleOpen(highlights)}
              />
            </div>
          </Grid>
        ) : (
          <Box />
        )}

        {specification ? (
          <Grid item xs={12} md={6}>
            <Typography
              variant={"h4"}
              color="black"
              fontFamily={"Poppins"}
              align="center"
            >
              Specifications
            </Typography>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={specification}
                alt="Specifications"
                style={{ maxWidth: "100%", cursor: "pointer" }}
                onClick={() => handleOpen(specification)}
              />
            </div>
          </Grid>
        ) : (
          <Box />
        )}
      </Grid>

      <Dialog open={open} maxWidth="lg" onClose={handleClose}>
        <DialogContent>
          <img src={imageSrc} alt="Full Image" style={{ width: "100%" }} />
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ position: "absolute", top: "5px", right: "5px" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Spacification;
