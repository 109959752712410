import React, { useState, useEffect } from "react";
import { CircularProgress, Typography, Box } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import Logo from "../../Images/3333.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const HeroView = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          "https://81zj99m8y7.execute-api.ap-south-1.amazonaws.com/samriddhi/get_home_page_scroll_images"
        );

        setImages(response.data["body-json"]["body"]["Item"]["images"]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching images:", error);
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const carouselSettings = {
    autoPlay: true,
    autoPlaySpeed: 2000,
    infinite: true,
    transitionDuration: 500,
    removeArrowOnDeviceType: ["tablet", "mobile"],
  };

  return (
    <Box sx={{ height: isMobileView ? 500 : 700 }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box sx={{ marginTop: isMobileView ? 5 : 8 }}>
          <Carousel
            {...carouselSettings}
            animation="slide"
            indicators={false}
            responsive={responsive}
          >
            {images.map((imageUrl, index) => (
              <Box
                key={index}
                sx={{
                  height: isMobileView ? 500 : 700,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={imageUrl}
                  alt={`Slide ${index}`}
                  loading="lazy"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "100%",
                    height: "auto",
                  }}
                />
              </Box>
            ))}
          </Carousel>
          <Box
            sx={{
              position: "absolute",
              top: isMobileView ? "16%" : "25%",
              left: isMobileView ? "14%" : "7%",
              transform: "translate(-50%, -50%)",
              textAlign: "left",
              padding: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              maxWidth: "600px",
            }}
          >
            <img
              src={Logo}
              alt="Logo"
              width={isMobileView ? 150 : 350}
              style={{ maxWidth: "100%" }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default HeroView;
