import React from "react";
import { Box, styled, Typography, Link } from "@mui/material";
import logo from "../../Images/download-brochure@2x.png";
import axios from "axios";
import fileDownload from "js-file-download";

const Brouch = () => {
  const CustomContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const CustomBox = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4),
    },
  }));

  const CustomImage = styled("img")(({ theme }) => ({
    width: "100%",
    maxWidth: "100%",
    height: "auto",
  }));

  return (
    <CustomContainer>
      <CustomBox>
        <Typography
          sx={{
            fontSize: "18px",
            color: "#5A6473",
            my: 4,
            fontFamily: "Poppins",
          }}
        >
          We have evolved as a reputed company with business verticals spanning
          across property development, construction, and infrastructure
          projects.
          <p>
            Our attention to detail, vision, and innovation has ensured that we
            are able to deliver quality that sets us apart from the rest.
          </p>
        </Typography>
      </CustomBox>
    </CustomContainer>
  );
};

export default Brouch;
