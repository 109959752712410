import { Box, Typography, styled, Card, CardContent } from "@mui/material";
import React, { useState } from "react";

const Animities = ({ amenitiesData, amenitiesTextData }) => {
  const [content, setContent] = useState(null);

  const CustomContainer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      marginBottom: theme.spacing(),
    },
  }));

  const ImgContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100%",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  }));

  const Divider = styled("div")(({ theme }) => ({
    width: "5%",
    height: "5px",
    backgroundColor: "#000339",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  }));

  const CardContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "30px",
    gap: theme.spacing(2),
  }));

  const CardItem = styled(Card)(({ theme }) => ({
    flex: "1 1 23%",
    [theme.breakpoints.down("sm")]: {
      flex: "1 1 100%",
    },
    width: "50px",
    height: "70px",
    backgroundColor: "white",
    borderRadius: "5px",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
  }));

  const CardContentItem = styled(CardContent)(({ theme }) => ({
    color: "black",
    padding: theme.spacing(2),
  }));

  const handleData = () => {
    if (amenitiesData && typeof amenitiesData === "string") {
      const imageContent = (
        <img src={amenitiesData} alt="No-item" className="w-screen h-3/6" />
      );
      if (Array.isArray(amenitiesTextData) && amenitiesTextData.length > 0) {
        const contentItems = amenitiesTextData.map((item, index) => (
          <CardItem key={index}>
            <CardContentItem>
              <Typography variant="body1">{item}</Typography>
            </CardContentItem>
          </CardItem>
        ));
        setContent(
          <CustomContainer>
            <ImgContainer>{imageContent}</ImgContainer>

            <CardContainer>{contentItems}</CardContainer>
          </CustomContainer>
        );
      } else {
        setContent(
          <CustomContainer>
            <ImgContainer>{imageContent}</ImgContainer>
          </CustomContainer>
        );
      }
    } else if (
      Array.isArray(amenitiesTextData) &&
      amenitiesTextData.length > 0
    ) {
      const contentItems = amenitiesTextData.map((item, index) => (
        <CardItem key={index}>
          <CardContentItem>
            <Typography variant="body1">{item}</Typography>
          </CardContentItem>
        </CardItem>
      ));
      setContent(
        <CustomContainer>
          <CardContainer>{contentItems}</CardContainer>
        </CustomContainer>
      );
    } else {
      setContent(null);
    }
  };

  useState(() => {
    handleData();
  }, [amenitiesTextData]);

  return (
    <CustomContainer>
      {content ? (
        <Box>
          <Box
            sx={{
              margin: {
                xs: 1,
                sm: 4,
                md: 6,
                lg: 8,
                xl: 10,
              },
            }}
          >
            <Typography
              variant={"h4"}
              color="black"
              fontFamily={"Poppins"}
              align="left"
            >
              AMENITIES
              <Divider />
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box />
      )}
      <Box
        sx={{
          margin: {
            xs: 1,
            sm: 2,
            md: 4,
            lg: 5,
            xl: 8,
          },
        }}
      >
        {content}
      </Box>
    </CustomContainer>
  );
};

export default Animities;
