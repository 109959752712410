import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Demo({ data }) {
  const { propId } = useParams();
  const [move, setMove] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    query: "",
  });
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEnquiryClick = () => {
    setMove(!move);
  };

  const handleQueryChange = (e) => {
    setFormData({ ...formData, query: e.target.value });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emptyFields = Object.values(formData).some((value) => value === "");
    if (emptyFields) {
      setFormError(true);
      return;
    }

    setIsLoading(true);

    try {
      await axios.post(
        "https://81zj99m8y7.execute-api.ap-south-1.amazonaws.com/samriddhi/query",
        { ...formData, id: propId }
      );

      toast.success("Enquiry submitted successfully!");

      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
        query: "",
      });

      setIsLoading(false);
      setFormError(false);
    } catch (error) {
      toast.error("Error submitting form. Please try again later.");

      setIsLoading(false);
    }
  };

  return (
    <>
      {data === "true" ? (
        <Box
          sx={{
            boxSizing: "border-box",
            padding: "17px",
            background: "rgba(255, 255, 255, 0.88)",
            borderRadius: "0px",
            transition: "all 0.35s ease-in-out 0s",
            position: "fixed",
            top: "25%",
            width: "310px",
            textAlign: "center",
            zIndex: 999,
            right: move ? "0px" : "-310px",
          }}
        >
          <Button
            sx={{
              font: "inherit",
              boxSizing: "border-box",
              margin: "0px",
              fontFamily: "inherit",
              fontSize: "inherit",
              lineHeight: "normal",
              textTransform: "none",
              overflow: "visible",
              appearance: "button",
              background: "rgb(55, 127, 73)",
              padding: "10px 20px",
              borderRadius: "0px 0px 5px 5px",
              border: "none",
              transition: "background 0.4s ease 0s",
              position: "fixed",
              zIndex: 9999,
              top: "50%",
              backgroundImage: "initial",
              color: "rgb(255, 255, 255)",
              cursor: "pointer",
              transform: `rotate(${move ? "90deg" : "90deg"})`,
              right: move ? "calc(310px - 42px)" : "-40px",
              "&:hover": {
                background: "green",
              },
            }}
            onClick={handleEnquiryClick}
          >
            Enquire Now
          </Button>

          <form
            id="stickForm"
            autoComplete="off"
            method="post"
            noValidate="novalidate"
            onSubmit={handleSubmit}
            sx={{
              boxSizing: "border-box",
              color: "rgb(255, 255, 255)",
              transform: `translateX(${move ? "0" : "-310px"})`,
            }}
          >
            <TextField
              id="name"
              name="name"
              type="text"
              required
              placeholder="Name"
              aria-required="true"
              value={formData.name}
              onChange={handleChange}
              error={formError && formData.name === ""}
              helperText={
                formError && formData.name === "" ? "Name is required" : ""
              }
              sx={{
                margin: "0px",
                outline: "0px",
                height: "35px",
                marginBottom: "13px",
                marginTop: 2,
                width: "100%",
              }}
            />
            <TextField
              id="email"
              name="email"
              type="email"
              required
              placeholder="Email"
              aria-required="true"
              value={formData.email}
              onChange={handleChange}
              error={formError && formData.email === ""}
              helperText={
                formError && formData.email === "" ? "Email is required" : ""
              }
              sx={{
                margin: "0px",
                outline: "0px",
                height: "35px",
                marginBottom: "13px",
                marginTop: 2,
                width: "100%",
              }}
            />

            <TextField
              id="phone"
              name="phone"
              type="number"
              required
              placeholder="Phone Number"
              aria-required="true"
              value={formData.phone}
              onChange={handleChange}
              error={formError && formData.phone === ""}
              helperText={
                formError && formData.phone === ""
                  ? "Phone number is required"
                  : ""
              }
              sx={{
                margin: "0px",
                outline: "0px",
                height: "35px",
                marginTop: 2,
                marginBottom: "10px",
                width: "100%",
              }}
            />
            <TextField
              id="message"
              name="message"
              type="text"
              required
              placeholder="Message"
              aria-required="true"
              value={formData.message}
              onChange={handleChange}
              error={formError && formData.message === ""}
              helperText={
                formError && formData.message === ""
                  ? "Message is required"
                  : ""
              }
              sx={{
                margin: "0px",
                outline: "0px",
                height: "35px",
                marginBottom: "13px",
                marginTop: 2,
                width: "100%",
              }}
            />

            <Select
              id="query"
              name="query"
              required
              value={formData.query}
              onChange={handleQueryChange}
              error={formError && formData.query === ""}
              sx={{
                margin: "0px",
                outline: "0px",
                height: "35px",
                marginBottom: "13px",
                marginTop: 2,
                width: "100%",
              }}
            >
              <MenuItem value="">Select Query</MenuItem>
              <MenuItem value="1Bhk">1Bhk</MenuItem>
              <MenuItem value="2Bhk">2Bhk</MenuItem>
              <MenuItem value="3Bhk">3Bhk</MenuItem>
              <MenuItem value="4Bhk">4Bhk</MenuItem>
              <MenuItem value="Duplex">Duplex</MenuItem>
            </Select>
            <TextField
              id="query-helper-text"
              name="query-helper-text"
              type="hidden"
              value={formData.query}
            />
            {formError && formData.query === "" && (
              <span style={{ color: "red" }}>Query is required</span>
            )}

            <div
              className="btn-group"
              sx={{
                position: "relative",
                display: "inline-block",
                verticalAlign: "middle",
                color: "rgb(255, 255, 255)",
                marginTop: "20px",
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Button
                  id="bsubmit"
                  className="_animate"
                  name="bSubmit"
                  type="submit"
                  sx={{
                    font: "inherit",
                    overflow: "visible",
                    marginTop: 5,
                    textTransform: "none",
                    cursor: "pointer",
                    borderRadius: "2px",
                    color: "rgb(255, 255, 255)",
                    border: "none",
                    outline: "0px",
                    background: "rgb(0, 12, 44)",
                    padding: "0px 30px",
                    height: "40px",
                    "&:hover": {
                      background: "green",
                    },
                  }}
                >
                  Send Us
                </Button>
              )}
            </div>
          </form>
          <ToastContainer
            position="top-center" // Set the position to top-center
            autoClose={3000} // Adjust the duration the toast is displayed (if needed)
            hideProgressBar={true} // Hide the progress bar
            style={{ marginTop: "40px" }} // Add margin-top
          />
        </Box>
      ) : null}
    </>
  );
}
