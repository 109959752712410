import { Box, styled, Typography, Link } from "@mui/material";
import React from "react";
import logo from "../../../Images/download-brochure@2x.png";
import fileDownload from "js-file-download";

const Brouchdw = ({ data }) => {
  const CustomContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center", // Align items in the center
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "right",
      textAlign: "right",
    },
  }));

  const CustomBox = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "right",
    justifyContent: "right",
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4),
    },
  }));

  const handleDownload = async () => {
    try {
      if (data) {
        const pdfUrl = data;

        const response = await fetch(pdfUrl);
        const blob = await response.blob();

        const filename = pdfUrl
          .substring(pdfUrl.lastIndexOf("/") + 1)
          .split("?")[0];

        fileDownload(blob, filename);
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <CustomContainer
      sx={{
        margin: {
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 2,
        },
      }}
    >
      <CustomBox>
        {" "}
        {/* Add a container for the logo */}
        {data && (
          <Link component="button" variant="body2" onClick={handleDownload}>
            <img src={logo} alt="Logo" className="w-fit h-fit" />
          </Link>
        )}
      </CustomBox>
      {/* Add margin to create space between the Link and other content */}
      <Box m={2}> {/* Adjust margin as needed */}</Box>
    </CustomContainer>
  );
};

export default Brouchdw;
