import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import NavbarHeader from "../NavbarHeader";
import FooterPage from "../Footer/FooterPage";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IconButton } from "@mui/material";

import { styled } from "@mui/system";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const CustomArrow = styled("div")({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 1,
  cursor: "pointer",
  fontSize: "100px",
  color: "white",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  padding: "2px",
  borderRadius: "50%",
  width: "50px",
  height: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CustomArrowLeft = styled(CustomArrow)({
  left: 0,
});

const CustomArrowRight = styled(CustomArrow)({
  right: 0,
});

const Tabs7 = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const [data, setData] = useState([]);
  const [updata, setUPdata] = useState([]);
  const [cmdata, setCMdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const sliderRef = useRef();

  useEffect(() => {
    Award();
    UPAward();
    CMAward();
  }, []);

  const Award = async () => {
    try {
      const response = await axios.post(
        "https://81zj99m8y7.execute-api.ap-south-1.amazonaws.com/samriddhi/ongoing_upcoming_completed_all_data",
        {
          project_status: "ongoing",
          property_type: "residential",
        }
      );
      setData(response.data["body-json"]["body"]["Items"]);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading state to false
    }
  };

  const CMAward = async () => {
    try {
      const response = await axios.post(
        "https://81zj99m8y7.execute-api.ap-south-1.amazonaws.com/samriddhi/ongoing_upcoming_completed_all_data",
        {
          project_status: "completed",
          property_type: "residential",
        }
      );
      setCMdata(response.data["body-json"]["body"]["Items"]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const UPAward = async () => {
    try {
      const response = await axios.post(
        "https://81zj99m8y7.execute-api.ap-south-1.amazonaws.com/samriddhi/ongoing_upcoming_completed_all_data",
        { project_status: "upcoming", property_type: "residential" }
      );
      setUPdata(response.data["body-json"]["body"]["Items"]);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

  const handleClick = (e, propId) => {
    e.preventDefault();

    navigate(`/PropertiesDetails/${propId}`);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToScroll: 2,
    initialSlide: 0,
    prevArrow: <CustomArrowLeft></CustomArrowLeft>,
    nextArrow: <CustomArrowRight></CustomArrowRight>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          cssEase: "linear",
          centerPadding: "10px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
          cssEase: "linear",
          centerPadding: "10px",
        },
      },
    ],
  };

  const CardContainer = ({ post }) => (
    <Card
      style={{
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
        width: isMobileView ? "100%" : "250px", // Adjusted card width
        height: isMobileView ? "auto" : "380px",
        margin: isMobileView ? "0 0 14px 0" : "0 10px 16px 0",

        ...(isMobileView && { marginBottom: "16px" }),
      }}
    >
      <Box>
        <img
          src={post["thumb_image"]}
          alt="house_image"
          style={{
            height: isMobileView ? "100%" : "200px",
            width: isMobileView ? "auto" : "300px",
          }}
        />
      </Box>

      <Box
        sx={{
          color: "white",
          padding: isMobileView ? "6px" : "10px",
          flexGrow: 1, // Allow content to expand
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // Space evenly between title and button
          backgroundColor: "whitesmoke",
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{
            fontWeight: "bold",
            fontSize: isMobileView ? "12px" : "16px",
            fontFamily: "Arial", // Adjusted font size
            textShadow: "1px 1px 1px rgba(0, 0, 0, 0.5)",
            color: "black",
          }}
        >
          {post["project_name"].toUpperCase()}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: isMobileView ? "12px" : "18px", // Adjusted font size
            fontFamily: "Poppins",
            color: "black",
          }}
        >
          {post["address"]["area"]}, &nbsp;
          {post["address"]["district"]}, &nbsp;
          {post["address"]["division"]}, &nbsp;
          {post["address"]["state"]}
        </Typography>
        <br />
        <Button
          variant="contained"
          size="small"
          color="success"
          onClick={(e) => handleClick(e, post["id"])}
          sx={{
            fontSize: isMobileView ? "10px" : "12px", // Adjusted font size
          }}
        >
          View Project
        </Button>
      </Box>
    </Card>
  );

  return (
    <Box
      style={{
        backgroundImage:
          "linear-gradient(to left, #82486b, #824f7b, #7f568c, #775f9c, #6b69ab, #6276ba, #5683c7, #4690d2, #41a4df, #43b7ea, #4ecaf3, #5fddfb)",
      }}
    >
      <NavbarHeader />

      <Box
        style={{
          backgroundSize: "cover",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "2rem",
        }}
      >
        <Typography
          variant="h4"
          component="div"
          style={{
            fontSize: isMobileView ? "30px" : "40px",

            fontWeight: "400",
            fontFamily: "Poppins",
            marginBottom: "1rem",
            marginTop: isMobileView ? "30px" : "60px",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Add text shadow
            color: "white", // Set the text color to white
          }}
        >
          Want to know more about Samriddhi Projects?
        </Typography>
        <Typography
          variant="h6"
          style={{
            fontSize: isMobileView ? "20px" : "25px",
            marginBottom: "1rem",
            color: "white",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            fontFamily: "Poppins",
          }}
        >
          Samriddhi Projects Details
        </Typography>
        <Typography
          variant="body1"
          style={{
            fontSize: isMobileView ? "14px" : "18px",
            color: "white",
            fontWeight: "100",
            lineHeight: 2,
            marginBottom: "1rem",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            fontFamily: "Poppins",
          }}
        >
          Listed under one of the best real estate companies in Hazaribagh,
          Samriddhi Projects has delivered statement properties over the years
          across the city. With the aesthetic appeal, sustainable design, and
          prime location, Samriddhi has taken the expectations of our customers
          to new heights.
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={4}
          width={isMobileView ? "100%" : "80%"}
          mx="auto"
          marginBottom={isMobileView ? 4 : 0}
        >
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            textColor="white"
            centered
            TabIndicatorProps={{
              style: {
                backgroundColor: "green",
                color: "white",
              },
            }}
          >
            <Tab
              label="Ongoing"
              sx={{
                color: "white",
                fontSize: isMobileView ? "12px" : "20px",
                padding: "10px",
                margin: "0 10px",
                height: 10,
                fontWeight: 80,
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
            />
            <Tab
              label="Completed"
              sx={{
                color: "white",
                fontSize: isMobileView ? "12px" : "20px",
                padding: "10px",
                margin: "0 10px",
                height: 10,
                fontWeight: 80,
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
            />
            <Tab
              label="Upcoming"
              sx={{
                color: "white",
                fontSize: isMobileView ? "12px" : "20px",
                padding: "10px",
                margin: "0 10px",
                height: 10,
                fontWeight: 80,
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              }}
            />
          </Tabs>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 300,
                width: 200,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box width="100%" maxWidth={isMobileView ? "100%" : "100%"}>
              <Slider
                {...settings}
                ref={(slider) => (sliderRef.current = slider)}
                slidesToShow={isMobileView ? 1 : 4}
              >
                {selectedTab === 0 &&
                  data.map((post, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: isMobileView ? "100%" : "300px",
                        marginTop: 5,
                      }}
                    >
                      <CardContainer post={post} />
                    </Box>
                  ))}
                {selectedTab === 1 &&
                  cmdata.map((post, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: isMobileView ? "100%" : "300px",
                        marginTop: 5,
                      }}
                    >
                      <CardContainer post={post} />
                    </Box>
                  ))}
                {selectedTab === 2 &&
                  updata.map((post, index) => (
                    <Box
                      key={index}
                      sx={{
                        width: isMobileView ? "100%" : "300px",
                        marginTop: 5,
                      }}
                    >
                      <CardContainer post={post} />
                    </Box>
                  ))}
              </Slider>
            </Box>
          )}
        </Box>
      </Box>
      <FooterPage />
    </Box>
  );
};

export default Tabs7;
