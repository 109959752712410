import React, { useState, useEffect } from "react";
import { Box, styled, Typography, IconButton } from "@mui/material";
import Carousel, { Navigation } from "react-spring-3d-carousel";
import { config } from "react-spring";
import CloseIcon from "@mui/icons-material/Close";

const Gallery = ({ data }) => {
  const [state, setState] = useState({
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    animationConfig: config.gentle,
    transformConfig: {
      scale: 2,
    },
  });
  const [image, setImage] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const setUnitvalue = () => {
    setImage(data["imagelist"]);
  };

  useEffect(() => {
    setUnitvalue();
  }, []);

  const slides = image.map((imageSrc, index) => ({
    content: (
      <img
        src={imageSrc}
        alt={`Image ${index + 1}`}
        onClick={() => handleImageClick(index)}
      />
    ),
    onClick: () =>
      setState((prevState) => ({ ...prevState, goToSlide: index })),
  }));

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setFullScreen(false);
  };

  const Divider = styled("div")(({ theme }) => ({
    width: "5%",
    height: "5px",
    backgroundColor: "#000339",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  }));

  return (
    <>
      <Box
        sx={{
          margin: {
            xs: 0, // Removed top margin for mobile view
            sm: 4,
            md: 6,
            lg: 8,
            xl: 10,
          },
        }}
      >
        <Typography variant="h4" color="black" align="left">
          Gallery
          <Divider />
        </Typography>
      </Box>
      <Box
        style={{
          width: "100%", // Made the slider full width for mobile view
          height: "300px", // Reduced the height for mobile view
          margin: "0 auto",
        }}
      >
        <Carousel
          slides={slides}
          goToSlide={state.goToSlide}
          offsetRadius={state.offsetRadius}
          showNavigation={state.showNavigation}
          animationConfig={state.animationConfig}
          transformConfig={state.transformConfig}
          slidesToShow={3}
          slidesToScroll={1}
          infinite // Set the slider to infinite
          autoplay
          autoplayInterval={1000}
          responsive
        >
          <Navigation />
        </Carousel>
      </Box>

      {fullScreen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <img
            src={image[selectedImageIndex]}
            alt={`Image ${selectedImageIndex + 1}`}
            style={{ maxWidth: "90%", maxHeight: "90%" }}
          />
          <IconButton
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "#fff",
            }}
            onClick={handleCloseFullScreen}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default Gallery;
