import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

export default function Component({ data }) {
  const { propId } = useParams();
  const [units, setUnits] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (data.units) {
      setUnits(data.units);
    }
  }, [data.units]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [move, setMove] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    query: "", // New query field
  });

  const [formError, setFormError] = useState(false);

  const handleEnquiryClick = () => {
    setMove(!move);
  };
  const handleQueryChange = (e) => {
    setFormData({ ...formData, query: e.target.value });
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emptyFields = Object.values(formData).some((value) => value === "");
    if (emptyFields) {
      setFormError(true);
      return;
    }

    try {
      await axios.post(
        "https://81zj99m8y7.execute-api.ap-south-1.amazonaws.com/samriddhi/query",
        { ...formData, id: propId }
      );

      toast.success("Enquiry submitted successfully!");

      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
        query: "", // Reset query field
      });
      setFormError(false);
    } catch (error) {
      toast.error("Error submitting the form. Please try again later.");
    }
  };

  const Divider = styled("div")(({ theme }) => ({
    width: "5%",
    height: "5px",
    backgroundColor: "#000339",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  }));

  return (
    <>
      {" "}
      {units.length >= 1 ? (
        <Box
          sx={{
            margin: {
              xs: 1,
              sm: 4,
              md: 6,
              lg: 8,
              xl: 10,
            },
          }}
        >
          <Typography
            variant="h4"
            fontFamily={"Poppins"}
            color="black"
            align="left"
          >
            Rate
            <Divider />
          </Typography>
        </Box>
      ) : (
        <Box />
      )}
      {units.length >= 1 ? (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "15px",
            color: "rgb(0, 0, 0)",
            letterSpacing: "0.4608px",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              width: "100%",
              marginBottom: "1rem",
              border: "1px solid rgb(222, 226, 230)",
            }}
          >
            <Table sx={{ borderCollapse: "collapse" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.75rem",
                      borderTop: "1px solid rgb(222, 226, 230)",
                      borderBottom: "0px",
                      fontFamily: "Poppins",
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    Type
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.75rem",
                      borderTop: "1px solid rgb(222, 226, 230)",
                      borderBottom: "0px",
                      fontFamily: "Poppins",
                      fontSize: 20,
                      fontWeight: 600,
                    }}
                  >
                    Unit Available
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.75rem",
                      borderTop: "1px solid rgb(222, 226, 230)",
                      borderBottom: "0px",
                      borderRight: "0px",
                      fontFamily: "Poppins",
                      fontSize: 20,
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Inquiry Regarding Rates
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.75rem",
                      borderTop: "1px solid rgb(222, 226, 230)",
                      borderBottom: "2px solid rgb(222, 226, 230)",
                      border: "0px",
                    }}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {units.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? "rgba(0, 0, 0, 0.05)" : "transparent",
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: "0.75rem",
                        border: "1px solid rgb(222, 226, 230)",
                        borderLeft: "0px",
                        borderTop: "0px",
                        borderBottom: "0px",
                        fontFamily: "Poppins",
                        fontSize: 20,
                      }}
                    >
                      {row.type.toUpperCase()}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.75rem",
                        border: "1px solid rgb(222, 226, 230)",
                        borderLeft: "0px",
                        borderTop: "0px",
                        borderBottom: "0px",
                        fontFamily: "Poppins",
                        fontSize: 20,
                      }}
                    >
                      {row.no_of_units}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.75rem",
                        borderTop: "1px solid rgb(222, 226, 230)",
                        border: "0px",
                      }}
                    >
                      <i
                        className="mi mi-rs-light"
                        sx={{
                          fontSize: "0.9vw",
                          fontWeight: 600,
                          fontFamily: "micon",
                        }}
                      />{" "}
                    </TableCell>
                    <TableCell sx={{ padding: "0.75rem", border: "0px" }}>
                      <Button
                        variant="contained"
                        color="info"
                        sx={{
                          borderRadius: "0.2rem",
                          fontSize: "0.875rem",
                          lineHeight: 1.5,
                          animation: "Gradient 3s infinite",
                          textShadow: "0 3px 6px #084ba1",
                          cursor: "pointer",
                        }}
                        onClick={handleOpenModal}
                      >
                        Click Here
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      ) : (
        <Box />
      )}
      {units ? (
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              width: 400,
              p: 4,
              textAlign: "center",
            }}
          >
            <Typography variant="h5" fontFamily={"Poppins"}>
              Query Form
            </Typography>
            <TextField
              name="name"
              label="Name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="phone"
              label="Phone Number"
              value={formData.phone}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              name="message"
              label="Message"
              value={formData.message}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Query Type</InputLabel>
              <Select
                name="query"
                value={formData.query}
                onChange={handleQueryChange}
              >
                <MenuItem value="2bhk">2 BHK</MenuItem>
                <MenuItem value="3bhk">3 BHK</MenuItem>
                <MenuItem value="duplex">Duplex</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>

            <ToastContainer
              position="top-bottom"
              autoClose={3000}
              hideProgressBar={true}
            />
          </Box>
        </Modal>
      ) : (
        <Box />
      )}
    </>
  );
}
