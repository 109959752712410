import { Box, Typography } from "@mui/material";

import HeroImg from "../../Images/3333.png";

// import { Link } from "react-router-dom";
// import { useTheme } from "@mui/material/styles";

import NavbarHeader from "../NavbarHeader";
import FooterPage from "../Footer/FooterPage";
import EmailIcon from "@mui/icons-material/Email";

import { Button, Stack, TextField, Grid } from "@mui/material";
import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useState } from "react";

import axios from "axios";

import { styled } from "@mui/material";

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
}));
const ImgContainer = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "center",

  [theme.breakpoints.down("sm")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const Cotact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const handleChange = (e) => {
    const phoneNumber = e.target.value;

    if (phoneNumber && !phoneNumber.match(/^[6-9]/)) {
      setPhoneError(
        "Invalid phone number. Please enter a valid 10-digit number starting from 6 to 9."
      );
    } else {
      setPhoneError(""); // Reset error if valid phone number is entered
    }

    setNumber(phoneNumber);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const values = JSON.stringify({
      name: name,

      email: email,
      message: message,
      phone: phone,
    });
    const ContactData = async () => {
      await axios
        .post(
          "https://81zj99m8y7.execute-api.ap-south-1.amazonaws.com/samriddhi/contact",

          values
        )
        .then((response) => {
          if (response.data["body-json"]["statusCode"] === 404) {
            toast.error(response.data["body-json"]["body"]);
          } else if (response.data["body-json"]["statusCode"] === 500) {
            toast.error(response.data["body-json"]["body"]);
          } else if (response.data["body-json"]["statusCode"] === 200) {
            toast.success("Form submitted successfully");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setName("");
      setEmail("");
      setNumber("");
      setMessage("");
    };
    //
    ContactData();
  };

  return (
    <Box>
      <NavbarHeader />
      <CustomBox>
        <CustomBox sx={{ flex: "1", marginTop: 5, padding: 5 }}>
          <Grid container direction="column" sx={{ height: "100%" }}>
            <Grid item xs={12} md={4}>
              <Box sx={{ p: 2 }}>
                <CustomBox className="flex  justify-center  ">
                  <Typography variant="h4" align="center" mb={2}>
                    Contact Us
                  </Typography>
                </CustomBox>
                <CustomBox className="flex  justify-center  ">
                  <Typography>
                    Get in touch today to step into your dream home
                  </Typography>
                </CustomBox>
                <form onSubmit={handleSubmit}>
                  <Grid direction="column">
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        margin="normal"
                        required
                      />
                      <TextField
                        fullWidth
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                        required
                        type="email"
                      />
                      <TextField
                        fullWidth
                        label="Phone Number"
                        value={phone}
                        onChange={handleChange}
                        margin="normal"
                        required
                        type="tel"
                        error={phoneError !== ""}
                        helperText={phoneError}
                      />

                      <TextField
                        fullWidth
                        label="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        margin="normal"
                        required
                        multiline
                        rows={4}
                      />
                      <Button variant="contained" type="submit">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </CustomBox>
        <CustomBox>
          <ImgContainer margin={5} marginTop={10}>
            <img src={HeroImg} alt="heroImg" width={800} height={"100%"} />
          </ImgContainer>
        </CustomBox>
      </CustomBox>
      <CustomBox style={{ display: "flex", borderRadius: "10px" }}>
        {/* Left 1/4 side for "Visit Us" and address */}
        <div
          style={{ width: "100%", padding: "16px", backgroundColor: "#f2f2f2" }}
        >
          <Typography
            sx={{ fontSize: 25, fontWeight: 500, fontFamily: "Poppins" }}
          >
            {" "}
            Visit Us
          </Typography>

          <p style={{ fontSize: 20, fontFamily: "Poppins" }}>
            Samriddhi Infraworks Private Limited . Opp. Mayuri Regency Hotel
          </p>
          <p style={{ fontSize: 20, fontFamily: "Poppins" }}>
            Katchari Chowk Near Jain Petrol Supply, Hazaribag
          </p>
          <p style={{ fontSize: 20, fontFamily: "Poppins" }}>
            (Jharkhand)-825301
          </p>
          <p style={{ fontSize: 20, fontFamily: "Poppins" }}>
            {" "}
            <PhoneIcon color="white" /> +919304371006
          </p>
          <p style={{ fontSize: 20, fontFamily: "Poppins" }}>
            {" "}
            <EmailIcon color="white" /> info@samriddhiinfraworks.com
          </p>
        </div>
      </CustomBox>
      <CustomBox>
        {/* Right 3/4 side for the iframe map */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3644.981980560049!2d85.3656793!3d23.996413399999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f49db222c87907%3A0xf4d0da96d3202e13!2sSamriddhi%20Infraworks!5e0!3m2!1sen!2sin!4v1698828011374!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </CustomBox>
      <FooterPage />
      <ToastContainer />
    </Box>
  );
};

export default Cotact;
