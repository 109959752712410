import realState from "../../Images/3333.png";
import { Box, styled, Typography } from "@mui/material";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import Video from "../Video/company2.mp4";
import React from "react";

const FirstPage = () => {
  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
    alignItems: "center",
    flexDirection: "row", // Set flexDirection to row by default

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));
  const VideoContainer = styled(Box)(({ theme }) => ({
    width: "50%",
    marginLeft: 25,

    [theme.breakpoints.down("sm")]: {
      width: "90%", // Use the full width on smaller screens
      height: "auto", // Allow the image to adjust its height
      marginLeft: 0,
    },
  }));

  return (
    <Box>
      <CustomBox>
        <VideoContainer>
          {/* Video Section */}
          <video width="100%" controls poster={realState}>
            <source src={Video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </VideoContainer>
        <Box sx={{ flex: "1", marginTop: 5, padding: 5 }}>
          <CustomBox className="flex justify-start">
            <LocationCityIcon style={{ fontSize: "42px", color: "darkblue" }} />
            &nbsp; &nbsp;
            <Typography
              fontFamily="Poppins"
              variant="h4"
              color="Blue"
              fontWeight={"bold"}
            >
              Samriddhi Infraworks Private Limited
            </Typography>
          </CustomBox>

          <Typography
            variant="body2"
            sx={{ fontSize: "18px", color: "#5A6473", my: 4 }}
            fontFamily="Poppins"
          >
            M/s Samriddhi Infraworks Private Limited (SIWPL) a sister concern
            private limited company of M/s Samriddhi Construction which was form
            in 2013. As per requirement of time company has formed SIWPL for
            better growth and further expansions.
          </Typography>

          <Typography
            variant="body2"
            sx={{ fontSize: "18px", color: "#5A6473" }}
            fontFamily="Poppins"
          >
            SIWPL with its Directors Mr. Suresh Kumar Jain and Mr. Saurabh Jain
            a combination of mature and young director are focused in giving its
            clients what they deserve.
          </Typography>

          <Typography
            variant="body2"
            sx={{ fontSize: "18px", color: "#5A6473", my: 4 }}
            fontFamily="Poppins"
          >
            SIWPL is characterized by hard work, respect for relationship and a
            drive to excel in anything they take up. They have upheld the
            tradition of giving their best to all projects since conception.
          </Typography>

          <Typography
            variant="body2"
            sx={{ fontSize: "18px", color: "#5A6473", my: 4 }}
            fontFamily="Poppins"
          >
            SIWPL value centric delivery, execution process and commitment to
            innovation have set the stage for the next phase of growth.
          </Typography>

          <Typography
            variant="body2"
            sx={{ fontSize: "18px", color: "#5A6473", my: 4 }}
            fontFamily="Poppins"
          >
            <b style={{ fontSize: 25, color: "black" }}>Vision :- </b> To
            deliver the best solution to customer/client provide complete
            transparency and broaden activity base by diversifying into
            infrastructure discipline so as to sustain a healthy growth rate.
          </Typography>

          <Typography
            variant="body2"
            sx={{ fontSize: "18px", color: "#5A6473", my: 4 }}
            fontFamily="Poppins"
          >
            <b style={{ fontSize: 25, color: "black" }}>Mission :- </b>To create
            a fair and courteous environment for clients, employees, vendors as
            well as the society.
          </Typography>
        </Box>
      </CustomBox>
    </Box>
  );
};

export default FirstPage;
