import {
  Container,
  styled,
  Typography,
  CardContent,
  Card,
  Tabs,
  Tab,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Bulding from "../../Images/NewBulding.jpg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const AboutSm = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTab1, setSelectedTab1] = useState(0);

  const [boardData, setBoardData] = useState([{}]);
  const [staffData, setStaffData] = useState([{}]);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleChange1 = (event, newValue) => {
    setSelectedTab1(newValue);
  };

  const CustomContainer = styled(Box)(({ theme }) => ({
    height: "100%",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
  }));

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  useEffect(() => {
    Board();
    Staff();
  }, []);

  const Board = async () => {
    try {
      const response = await axios.get(
        "https://81zj99m8y7.execute-api.ap-south-1.amazonaws.com/samriddhi/board_of_directors_all_data"
      );
      const data = response.data["body-json"]["body"]["Items"];
      setBoardData(data);
      // console.log(data);
    } catch (error) {
      console.log("Error", error);
    }
  };
  const Staff = async () => {
    try {
      const response = await axios.get(
        "https://81zj99m8y7.execute-api.ap-south-1.amazonaws.com/samriddhi/get_board_of_staffs"
      );
      const data = response.data["body-json"]["body"];
      setStaffData(data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          marginTop: "60px",
          paddingBottom: "30px",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h3"
          component="h2"
          style={{
            marginBottom: "0.5rem",
            fontWeight: 500,
            padding: isMobileView ? "5px" : "5px",
            color: "black",
            fontFamily: "Poppins",
          }}
        >
          Samriddhi Infraworks Private Limited
        </Typography>
        <Typography
          component="p"
          style={{
            marginBottom: "2rem",
            color: "rgb(141, 162, 181)",
            padding: isMobileView ? "10px" : "25px",
            fontFamily: "Poppins",
            fontSize: "18px",
          }}
        >
          Samriddhi Infraworks Private Limited is a renowned real estate company
          specializing in residential and commercial developments. Known for
          innovative designs, modern amenities, and environmental
          sustainability, it's a trusted choice for both homebuyers and
          investors. The company's commitment to quality and reliability has
          established its position as a leading player in the industry.
          <br />
          <br />
          <Typography
            component="span"
            style={{
              color: "lightseagreen",
              fontWeight: 500,
              fontSize: "18px",
              padding: isMobileView ? "10px" : "30px",
              fontFamily: "Poppins",
            }}
          >
            "Samriddhi Infraworks Limited is guided by an experienced director
            and supported by a dedicated team, ensuring excellence in real
            estate."
          </Typography>
        </Typography>
      </Box>
      <Container
        sx={{
          backgroundColor: "#2D4356",
          padding: "25px",
          borderRadius: "10px",
        }}
      >
        <CustomBox>
          <CustomContainer>
            <CustomBox className="flex flex-row mt-4">
              <div className="w-11/12 ml-28 mr-20 text-base mt-14">
                <Typography
                  variant="h4"
                  color={"#b7b37e"}
                  fontWeight={"bold"}
                  fontSize={"33px"}
                  fontFamily={"Poppins"}
                >
                  Imagineering the Future
                </Typography>
                <Typography
                  color={"#efefef"}
                  fontSize={"18px"}
                  lineHeight={"30px"}
                  fontFamily={"Poppins"}
                >
                  Imagineering – A Blend of Imagination and Engineering to build
                  dream spaces that exceed the expectation of our customers.
                  Disruptive thinking and constantly challenging the norm with
                  new ideas has been the key to offer and create developments
                  “ahead of time” every time. Our well-organized working
                  methods, extensive market presence, strong and diverse
                  portfolio, streamlined manufacturing capabilities and
                  cost-effectiveness give us the edge to not just compete but
                  rise above the competition.
                </Typography>
              </div>
              <div className="w-9/12 h-5/6 mt-8">
                <img src={Bulding} alt="demoImg" />
              </div>
            </CustomBox>
          </CustomContainer>
        </CustomBox>
        <Box>
          <div className="w-full flex justify-center items-center text-base mt-14">
            <Typography
              variant="h4"
              color={"#b7b37e"}
              fontWeight={"bold"}
              fontSize={"33px"}
            >
              Board of Directors
            </Typography>
          </div>
          <Box width={"100%"}>
            <Box>
              <Card className="col-span-3   w-full mt-14">
                <Tabs
                  value={selectedTab}
                  onChange={handleChange}
                  scrollButtons="auto" // Display scroll buttons when needed
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#E7B10A",
                      color: "white",
                    },
                  }}
                  variant="fullWidth"
                  sx={{
                    direction: "flex",
                    flex: "column",
                    backgroundColor: "#435B66",
                    fontSize: 45,
                    width: "100%",
                    "& .MuiTab-root": {
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#00235B",
                        color: "white",
                      },
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#00235B",
                      color: "white",
                    },
                  }}
                >
                  {boardData.length === 0 && (
                    <Tab label="No Data Available" disabled />
                  )}
                  {boardData.map((director, index) => (
                    <Tab
                      key={index}
                      label={`  ${director.name}`}
                      sx={{
                        color: "white",
                      }}
                    />
                  ))}
                </Tabs>

                {boardData.length === 0 && (
                  <Card sx={{ maxWidth: "100%" }}>
                    <CardContent>
                      <Typography variant="body2">
                        No data available.
                      </Typography>
                    </CardContent>
                  </Card>
                )}
                {boardData.map((director, index) => (
                  <Box
                    key={index}
                    sx={{
                      maxWidth: "100%",
                      margin: 5,
                      display: selectedTab === index ? "block" : "none",
                    }}
                  >
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                      ></Typography>
                      <div className="w-9/12 ">
                        <img src={director.image} alt="CEO" />
                        <p
                          className="  font text-xl mt-5 text-lime-400 "
                          color="orange"
                        >
                          ({director.role})
                        </p>
                        <p className="  font text-xl mt-5">{director.msg}</p>
                      </div>
                    </CardContent>
                  </Box>
                ))}
              </Card>
            </Box>
          </Box>
        </Box>

        {staffData.length >= 1 ? (
          <Box>
            <div className="w-full flex justify-center items-center text-base mt-14">
              <Typography
                variant="h4"
                color={"#b7b37e"}
                fontWeight={"bold"}
                fontFamily={"Poppins"}
              >
                Our Staff
              </Typography>
            </div>
            <Box width={"100%"}>
              <Box>
                <Card className="col-span-3   w-full mt-14">
                  <Tabs
                    value={selectedTab1}
                    onChange={handleChange1}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#E7B10A",
                      },
                    }}
                    variant="fullWidth"
                    sx={{
                      direction: "flex",
                      flex: "column",
                      backgroundColor: "#435B66",
                      fontSize: 45,
                      width: "100%",
                      "& .MuiTab-root": {
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#00235B",
                          color: "white",
                        },
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#00235B",
                        color: "white",
                      },
                    }}
                  >
                    {staffData.length === 0 && (
                      <Tab label="No Data Available" disabled />
                    )}
                    {staffData.map((staff, index) => (
                      <Tab
                        key={index}
                        label={`  ${staff.name}`}
                        sx={{
                          color: "white",
                        }}
                      />
                    ))}
                  </Tabs>
                  {staffData.length === 0 && (
                    <Card sx={{ maxWidth: "100%" }}>
                      <CardContent>
                        <Typography variant="body2" color={"white"}>
                          No data available.
                        </Typography>
                      </CardContent>
                    </Card>
                  )}
                  {staffData.map((staff, index) => (
                    <Box
                      key={index}
                      sx={{
                        maxWidth: "100%",
                        margin: 5,
                        display: selectedTab1 === index ? "block" : "none",
                      }}
                    >
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                        ></Typography>
                        <div className="w-9/12 ">
                          <img src={staff.image} alt="CEO" />
                          <p
                            className="  font text-xl mt-5 text-lime-400 "
                            color="orange"
                          >
                            ({staff.role})
                          </p>
                          <p className="  font text-xl mt-5">{staff.msg}</p>
                        </div>
                      </CardContent>
                    </Box>
                  ))}
                </Card>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box />
        )}
      </Container>
    </>
  );
};

export default AboutSm;
