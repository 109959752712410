import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Tab,
  Tabs,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";

const FloorPlan = ({ data, floor, masterPlan }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTab1, setSelectedTab1] = useState(0);
  const [units, setUnits] = useState([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Add touch event handler for mobile view
  const handleImageTouch = (image) => {
    setSelectedImage(image);
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setSelectedImage(null);
    setPopupOpen(false);
  };

  useEffect(() => {
    if (data.units) {
      setUnits(data.units);
    }
  }, [data.units]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleChange1 = (event, newValue) => {
    setSelectedTab1(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Box
        bgcolor=" rgba(0, 0, 0, 0.05)"
        color="#fff"
        py={3}
        px={4}
        borderRadius={0.25}
        fontFamily="PlayfairDisplay-Regular"
        fontSize={16}
        lineHeight="24px"
        boxShadow={1}
        marginTop={10}
        sx={{
          margin: {
            xs: 1,
            sm: 4,
            md: 6,
            lg: 8,
            xl: 10,
          },
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              label="Master Plan"
              sx={{
                fontFamily: "NunitoSans-Regular",
                fontSize: 20,
                color: "rgb(69, 90, 67)",
                textTransform: "none",
                border: "1px solid rgb(69, 90, 67)",
                borderRadius: "0.25rem",
                padding: "15px 25px",
                marginLeft: 0,
                backgroundColor:
                  selectedTab === 0 ? "rgb(71, 91, 68)" : "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&.Mui-selected": {
                  backgroundColor: "transparent",
                  color: "blue",
                  fontSize: "20px",
                },
              }}
            />
            <Tab
              label="Floor Plans"
              sx={{
                fontFamily: "NunitoSans-Regular",
                fontSize: 20,
                color: "rgb(69, 90, 67)",
                textTransform: "none",
                border: "1px solid rgb(69, 90, 67)",
                borderRadius: "0.25rem",
                padding: "15px 25px",
                marginLeft: 1,
                backgroundColor:
                  selectedTab === 1 ? "rgb(71, 91, 68)" : "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&.Mui-selected": {
                  backgroundColor: "transparent",
                  color: "blue",
                  fontSize: "20px",
                },
              }}
            />
            <Tab
              label="Unit Plans"
              sx={{
                fontFamily: "NunitoSans-Regular",
                fontSize: 20,
                color: "rgb(69, 90, 67)",
                textTransform: "none",
                border: "1px solid rgb(69, 90, 67)",
                borderRadius: "0.25rem",
                padding: "15px 25px",
                marginLeft: 1,
                backgroundColor:
                  selectedTab === 2 ? "rgb(71, 91, 68)" : "transparent",
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&.Mui-selected": {
                  backgroundColor: "transparent",
                  color: "blue",
                  fontSize: "20px",
                },
              }}
            />
          </Tabs>
        </Box>
        <Box p={3}>
          {selectedTab === 0 && (
            <Box
              display="block"
              sx={{
                transition: "opacity 0.15s linear 0s",
                "&:hover": {
                  transform: "scale(1.1)", // Apply zoom on hover
                },
              }}
              onTouchStart={() => handleImageTouch(masterPlan)}
            >
              {masterPlan ? (
                <img src={masterPlan} alt="Master Plan" />
              ) : (
                <Box />
              )}
            </Box>
          )}
          {selectedTab === 1 && (
            <>
              <Box
                display="block"
                sx={{
                  transition: "opacity 0.15s linear 0s",
                  "&:hover": {
                    transform: "scale(1.1)", // Apply zoom on hover
                  },
                }}
                onTouchStart={() => handleImageTouch(floor)}
              >
                {floor ? (
                  <img
                    src={floor}
                    alt="Floor Plan"
                    height="50%"
                    width="90%"
                    style={{ margin: "0 auto" }}
                  />
                ) : (
                  <Box />
                )}
              </Box>
            </>
          )}

          {selectedTab === 2 && (
            <>
              <Tabs
                value={selectedTab1}
                onChange={handleChange1}
                textColor="primary"
                indicatorColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {units.map((unit, index) => (
                  <Tab
                    key={index}
                    label={unit.type}
                    sx={{
                      fontFamily: "NunitoSans-Regular",
                      fontSize: 20,

                      color: "rgb(69, 90, 67)",
                      textTransform: "none",
                      border: "1px solid rgb(69, 90, 67)",
                      borderRadius: "0.25rem",
                      padding: "15px 25px",
                      marginLeft: index === 0 ? 0 : 1,
                      backgroundColor:
                        index === selectedTab1
                          ? "rgb(71, 91, 68)"
                          : "transparent",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "blue",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "transparent",
                        color: "blue",
                        fontSize: "20px",
                      },
                    }}
                  />
                ))}
              </Tabs>
              <Box
                p={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {units.map((unit, index) => (
                  <Box
                    key={index}
                    display={index === selectedTab1 ? "block" : "none"}
                    sx={{
                      transition: "opacity 0.15s linear 0s",
                      "&:hover": {
                        transform: "scale(1.1)", // Apply zoom on hover
                      },
                    }}
                    onTouchStart={() => handleImageTouch(unit.image)}
                  >
                    <Typography
                      variant="subtitle1"
                      color="green"
                      fontSize={25}
                      fontFamily={"Poppins"}
                      sx={{
                        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                        fontWeight: "bold",
                      }}
                    >
                      Number of units available: {unit.no_of_units}
                    </Typography>
                    {unit ? (
                      <img
                        src={unit.image}
                        alt={unit.label}
                        height="50%"
                        width="90%"
                        style={{ margin: "0 auto" }}
                      />
                    ) : (
                      <Box />
                    )}
                  </Box>
                ))}
              </Box>
            </>
          )}
          <Dialog
            open={popupOpen}
            onClose={handleClosePopup}
            maxWidth="md"
            fullWidth
          >
            <DialogContent>
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Popup Image"
                  style={{ width: "100%", height: "auto" }}
                />
              )}
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
    </Container>
  );
};

export default FloorPlan;
