import React, { useState, useEffect } from "react";
import { CircularProgress, Typography, Box } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const HeroView = ({ data }) => {
  const [image, setImage] = useState([]);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
  const setUnitvalue = () => {
    setImage(data["imagelist"]);
  };

  useEffect(() => {
    setUnitvalue();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const carouselSettings = {
    autoPlay: true,
    autoPlaySpeed: 2000,
    infinite: true,
    transitionDuration: 500,
    removeArrowOnDeviceType: ["tablet", "mobile"],
  };

  return (
    <Box sx={{ height: isMobileView ? 500 : 700 }}>
      {image.length === 0 ? (
        <CircularProgress />
      ) : (
        <div style={{ position: "relative" }}>
          <Carousel
            {...carouselSettings}
            animation="slide"
            indicators={false}
            responsive={responsive}
          >
            {image.map((imageUrl, index) => (
              <Box
                key={index}
                sx={{
                  height: isMobileView ? 500 : 700,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  key={index}
                  src={imageUrl}
                  alt={`Slide ${index}`}
                  loading="lazy"
                  // style={{
                  //   width: isMobileView ? "100%" : "100%",
                  //   height: isMobileView ? "100%" : "100%",
                  // }}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "100%",
                    height: "auto",
                  }}
                />
              </Box>
            ))}
          </Carousel>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              backdropFilter: "blur(6px)",
              padding: "2rem",
            }}
          >
            <Typography
              variant={"h3"}
              color="white"
              align="center"
              boxShadow={"shadow-lg"}
              fontFamily={"Poppins"}
              fontWeight={700}
            >
              DON'T JUST CHANGE YOUR HOME
            </Typography>
            <Typography
              align="center"
              color="white"
              variant={"h6"}
              fontSize={20}
              sx={{ fontFamily: "Poppins" }}
              fontWeight="bold"
            >
              CHANGE YOUR PERSPECTIVE
            </Typography>
          </Box>
        </div>
      )}
    </Box>
  );
};

export default HeroView;
