import styled from "@emotion/styled";
import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import HeroImg from "../../Images/IMAGES.jpg";
import Archi from "../../Images/Lal.jpg";
import { Link } from "react-router-dom";

const StyleBox = styled(Box)({
  height: "100vh",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  margin: "20px", // Add margin for gap
  border: "2px solid black", // Add border
  transition: "background-color 0.3s ease", // Transition for background color change
  position: "relative", // Position relative for shadow effect
  "&:hover": {
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",

      backgroundColor: "rgba(0, 0, 0, 0.3)", // Darken the background color on hover
      zIndex: 1,
      transition: "opacity 0.3s ease", // Transition for opacity change
    },
  },
  "@media (max-width: 768px)": {
    margin: 0, // Adjust margin for smaller screens
  },
});

const StyledTypography = styled(Typography)({
  margin: "40% 50px 25% 50px",
  opacity: "3",
  flexDirection: "column",
  color: "white",
  position: "relative",
  zIndex: 2,
  "@media (max-width: 768px)": {
    margin: 0, // Adjust margin for smaller screens
    marginTop: 200,
  },
});
const Typography1 = styled(Typography)({
  "@media (max-width: 768px)": {
    margin: 2, // Adjust margin for smaller screens
    fontSize: "40px",
  },
});

const Categories = () => {
  return (
    <Box>
      <Stack direction={{ xs: "column", sm: "row" }}>
        <StyleBox
          sx={{
            backgroundImage: `url(${Archi})`,
            width: "100%",
          }}
          className="transition-all duration-500 hover:opacity-80"
        >
          {/* ... Existing code remains unchanged ... */}
          <StyledTypography align="center" sx={{ fontWeight: 600 }}>
            <div
              style={{
                position: "relative",
                width: "fit-content",
                backdropFilter: "blur(8px)",
                backgroundColor: "rgba(100, 100, 255, 0.3)",
                borderRadius: "8px",
                padding: "16px",
              }}
            >
              <Typography1 variant="h3" sx={{ fontWeight: 600 }}>
                RESIDENTIAL
              </Typography1>
            </div>

            <br />
            <Link to={"/tab7"}>
              <Button
                variant="contained"
                sx={{
                  width: "200px",
                  color: "yellow",
                  ":hover": {
                    color: "white",
                    opacity: 1,
                    border: "2px solid white",
                  },
                  fontFamily: "serif",
                  backgroundColor: "black",
                }}
              >
                View All Projects
              </Button>
            </Link>
          </StyledTypography>
        </StyleBox>
        <StyleBox
          sx={{
            backgroundImage: `url(${HeroImg})`,
            width: "90%",
          }}
          className="transition-all duration-500 hover:opacity-80"
        >
          {/* ... Existing code remains unchanged ... */}
          <StyledTypography align="center" sx={{ fontWeight: 600 }}>
            <div
              style={{
                position: "relative",
                width: "fit-content",
                backdropFilter: "blur(8px)",
                backgroundColor:
                  "rgba(100, 100, 255, 0.3)" /* Adjust the color and opacity as needed */,
                borderRadius:
                  "8px" /* Optional: Add border radius for rounded corners */,
                padding: "16px" /* Optional: Adjust padding as needed */,
              }}
            >
              <Typography1 variant="h3" sx={{ fontWeight: 600 }}>
                COMMERCIAL
              </Typography1>
            </div>
            <br />
            <Link to={"/tab6"}>
              <Button
                variant="contained"
                sx={{
                  width: "200px",
                  color: "yellow",
                  ":hover": {
                    color: "white",
                    opacity: 1,
                    border: "2px solid white",
                  },
                  fontFamily: "serif",
                  backgroundColor: "black",
                }}
              >
                View All Projects
              </Button>
            </Link>
          </StyledTypography>
        </StyleBox>
      </Stack>
    </Box>
  );
};

export default Categories;
