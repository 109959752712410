import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  styled,
  CircularProgress,
  Button,
} from "@mui/material";

const HeroView = ({ data }) => {
  const [approval, setapproval] = useState([]);

  const setUnitvalue = () => {
    setapproval(data["aprovals"]);
  };

  useEffect(() => {
    setUnitvalue();
  }, []);

  const Divider = styled("div")(({ theme }) => ({
    width: "5%",
    height: "5px",
    backgroundColor: "#000339",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  }));
  const handleDownload = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  return (
    <>
      {approval.length >= 1 ? (
        <Box
          sx={{
            margin: {
              xs: 1,
              sm: 4,
              md: 6,
              lg: 8,
              xl: 10,
            },
          }}
        >
          <Typography
            variant="h4"
            color="black"
            align="left"
            fontFamily={"Poppins"}
          >
            Approvals
            <Divider />
          </Typography>
        </Box>
      ) : (
        <Box />
      )}

      {approval.map((test, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={test.image}
            alt={`Approval ${index + 1}`}
            height={800}
            width={800}
            onError={(e) => {
              e.target.src = ""; // Set an empty source for the image on error
            }}
          />
          <Typography sx={{ fontSize: 25, fontFamily: "Poppins" }}>
            {test.name}
          </Typography>
          <Button
            sx={{ ml: "10px" }}
            variant="outlined"
            onClick={() => handleDownload(test["pdf"])}
          >
            Download PDF
          </Button>
        </Box>
      ))}
    </>
  );
};

export default HeroView;
