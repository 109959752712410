import React from "react";
import {
  Box,
  Card,
  CardMedia,
  Grid,
  Container,
  Typography,
  styled,
} from "@mui/material";

const VideoDisplay = ({ videoItem }) => {
  const Divider = styled("div")(({ theme }) => ({
    width: "5%",
    height: "5px",
    backgroundColor: "#000339",
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  }));

  return (
    <>
      {videoItem ? (
        <Box
          sx={{
            margin: {
              xs: 1,
              sm: 4,
              md: 6,
              lg: 8,
              xl: 10,
            },
          }}
        >
          <Typography
            variant={"h4"}
            fontFamily={"Poppins"}
            color="black"
            align="left"
          >
            Videos
            <Divider />
          </Typography>
        </Box>
      ) : (
        <Box />
      )}
      <Container>
        {videoItem ? (
          <Box py={3}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CardMedia
                    component="video"
                    controls
                    alt="Video"
                    width="100%"
                  >
                    <source src={videoItem} type="video/mp4" />
                    Your browser does not support the video tag.
                  </CardMedia>
                  <Typography variant="h6" align="center">
                    {videoItem.title}
                  </Typography>
                  <Typography variant="body2" align="center">
                    {videoItem.description}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box />
        )}
      </Container>
    </>
  );
};

export default VideoDisplay;
