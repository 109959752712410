import { styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import houseCard from "../../Images/pngegg.png";
import { useState, useEffect } from "react";
import axios from "axios";

const Clients = () => {
  const [mydata, setData] = useState({});

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(10),
    alignItems: "center",
    backgroundColor: "#fff",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  }));

  const ImgContainer = styled(Box)(({ theme }) => ({
    width: "50%",
    height: 600,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%", // Adjust width for smaller screens
      height: "auto",
    },
  }));

  const LargeText = styled(Typography)(({ theme }) => ({
    fontSize: "64px",
    color: "#7F5283",
    fontWeight: 700,
    fontFamily: "Poppins",
    [theme.breakpoints.down("md")]: {
      fontSize: "32px",
    },
  }));

  const SmallText = styled(Typography)(({ theme }) => ({
    fontSize: "32px",
    color: "#3D3C42",
    fontWeight: 600,
    fontFamily: "Poppins",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
  }));

  const TextFlexbox = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(7),
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0, 5, 0, 5),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: theme.spacing(2),
    },
  }));

  const Divider = styled("div")(({ theme }) => ({
    width: "13%",
    height: "5px",
    backgroundColor: "#000339",
    [theme.breakpoints.down("md")]: {
      margin: "0 auto", // Center the divider on smaller screens
    },
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://81zj99m8y7.execute-api.ap-south-1.amazonaws.com/samriddhi/review_all_data"
        );
        setData(response.data["body-json"]["body"]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Box className="bg-cyan-950">
      <CustomBox>
        <ImgContainer>
          <img src={houseCard} alt="house" />
        </ImgContainer>
        <Box>
          <Divider />
          <Typography
            sx={{
              fontSize: "35px",
              color: "#000339",
              fontWeight: 700,
              fontFamily: "Poppins",
              my: 3,
            }}
          >
            You’ve found a neighborhood you love.
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              color: "#5A6473",
              lineHeight: "27px",
              fontFamily: "Poppins",
              my: 4,
            }}
          >
            When you own a home, you commit to living in one location for a
            period of time. We are here to ensure that this will be a memorable
            experience for you.
          </Typography>
        </Box>
      </CustomBox>
      <TextFlexbox>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: "Poppins",
          }}
        >
          <LargeText>{mydata.years_of_experience}+</LargeText>
          <SmallText>Years of experience</SmallText>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: "Poppins",
          }}
        >
          <LargeText> {mydata.projects}+</LargeText>
          <SmallText>Projects done</SmallText>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: "Poppins",
          }}
        >
          <LargeText>{mydata.happy_client}+</LargeText>
          <SmallText>Happy Client</SmallText>
        </Box>
      </TextFlexbox>
      <TextFlexbox>
        <Box
          flex={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: "Poppins",
          }}
        >
          <LargeText>{mydata.sq_feet_delivered}+</LargeText>
          <SmallText>Sq Feet Delivered</SmallText>
        </Box>
        <Box
          flex={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontFamily: "Poppins",
          }}
        >
          <LargeText>{mydata.staff_contractors}+</LargeText>
          <SmallText>Staff Contractors</SmallText>
        </Box>
      </TextFlexbox>
    </Box>
  );
};

export default Clients;
