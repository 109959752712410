import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          elevation: 8,
          sx: {
            width: "100%",
            height: "100%",
            color: "white",
            backgroundColor: "rgba(30, 139, 195, 0.8)",
            fontSize: 50,
          },
        }}
      >
        <CloseIcon
          onClick={() => setOpenDrawer(false)}
          sx={{
            marginLeft: 5,
            marginTop: 5,
            cursor: "pointer",
            color: "yellow",
            fontSize: 50,
          }}
        />
        <List sx={{ marginLeft: 20, marginTop: 5 }}>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontSize: "25px",
                  fontFamily: "Poppins",
                  "&:hover": {
                    textDecoration: "underline yellow",
                  },
                },
              }}
            >
              <Link to="/">Home</Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontSize: "25px",
                  fontFamily: "Poppins",
                  "&:hover": {
                    textDecoration: "underline yellow",
                  },
                },
              }}
            >
              <Link to="/about">About Us</Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontSize: "25px",
                  fontFamily: "Poppins",
                  "&:hover": {
                    textDecoration: "underline yellow",
                  },
                },
              }}
            >
              <Link to="/career">Career</Link>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  fontSize: "25px",
                  fontFamily: "Poppins",
                  "&:hover": {
                    textDecoration: "underline yellow",
                  },
                },
              }}
            >
              <Link to="/contact">Contact Us</Link>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton
        onClick={() => setOpenDrawer(!openDrawer)}
        sx={{ marginLeft: "auto", color: "yellow" }}
      >
        <MenuIcon />
      </IconButton>
    </>
  );
}

export default DrawerComponent;
